<template>
    <AdminProductList />
</template>

<script>
import AdminProductList from './AdminProductList.vue'

export default {
    components: { AdminProductList },
    setup() {
    },
}
</script>

<style lang="scss" scoped>
</style>